// action types
export const LOAD = "LOAD";
export const LOAD_SETTINGS = "LOAD_SETTINGS";
export const EDIT = "EDIT";
export const CHANGE_PLAN = "CHANGE_PLAN";
export const ACCEPT_PLAN = "ACCEPT_PLAN";

export const LOAD_PRODUCTS = "LOAD_PRODUCTS";

// mutation types
export const RECEIVE = "RECEIVE";
export const RECEIVE_SETTINGS = "RECEIVE_SETTINGS";
export const SET_STATUS = "SET_STATUS";
export const SET_TARIFF_REDIRECT = "SET_TARIFF_REDIRECT";

export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";