import Http from "@/services/http";
import { service_url } from "@/services/config";

export function loadTariffs () {
    return Http._axios({
        url: `${service_url}/api/v1/templates/`,
        method: 'get'
    })
}
export function checkCode (code: string) {
    return Http._axios({
        url: `${service_url}/api/v1/discounts/${code}/`,
        method: 'get'
    })
}