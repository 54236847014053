import * as TYPES from "./types";
import {IAction, IHeaderSettings} from "@/store/modules/layout/interfaces";

export default {
    [TYPES.ON_TOGGLE_ASIDE](state: any, payload: IHeaderSettings) {
        state.is_open_aside = payload;
    },
    [TYPES.SET_SETTINGS](state: any, payload: IAction) {
        state.settings = {...payload};
    },
}