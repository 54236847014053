import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
export * from "./types";

const state = {
    status: { change_field: null },
    shop: {},
    settings: {},
    tariffs_redirect_url: '',
    products: []
};

export default {
    state,
    actions,
    mutations,
    getters
};
