import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: 'base',
        redirect: "/guides",
        component: () => import('@/scenes/AuthPage.vue'),
        children: [
            {
                path: '/settings',
                name: 'settings',
                component: () => import('@/containers/Settings.vue')
            },
            {
                path: '/tariffs',
                name: 'tariffs',
                component: () => import('@/containers/Tariffs.vue')
            },
            {
                path: '/guides/create',
                name: 'guide-create',
                component: () => import('@/containers/Guide/Guide.vue')
            },
            {
                path: '/guides/:id',
                name: 'guide-edit',
                component: () => import('@/containers/Guide/Guide.vue')
            },
            {
                path: '/guides',
                name: 'guides',
                component: () => import('@/containers/Guide/GuidesList.vue')
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/scenes/Login.vue')
    },
    {
        path: '/complete/shopify',
        name: 'shopify_complete',
        component: () => import('@/containers/Complete/ShopifyComplete.vue')
    },
    {
        path: '/charge/accept',
        name: 'charge-accept',
        component: () => import('@/containers/Complete/TariffComponent.vue')
    },
    {
        path: '/policy',
        name: 'policy',
        component: () => import('@/scenes/Policy.vue')
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('@/scenes/Faq.vue')
    },
    {
        path: '*',
        component: () => import('@/scenes/PageNotFound.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
