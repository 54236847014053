export enum LIST_NAMES{
    Guides = 'tables',
    Templates = 'templates',
    Products = 'products',
    Tags = 'tags',
    Vendors = 'vendors',
    Product_types = 'product_types',
}

export interface ILoadParameters{
    listName: LIST_NAMES,
    filter: string
}

export interface ICreateParameters{
    listName: LIST_NAMES,
    data: any
}

export interface IDeleteParameters{
    listName: LIST_NAMES,
    id: string
}

export interface IEditParameters{
    listName: LIST_NAMES,
    id: string,
    data: any
}

export interface ILoadDetailsParameters{
    listName: LIST_NAMES,
    id: string
}

export interface IState {
    [key: string]: any
}

export interface IReceivePayload{
    listName: LIST_NAMES,
    results: any[],
    count: number
}

export interface IReceiveDetailsPayload{
    listName: LIST_NAMES,
    data: ITable
}

export interface ITemplate{
    listName: LIST_NAMES,
    results: any[],
    count: number
}

export interface ITemplateListItem{
    id: number,
    is_global: boolean,
    name: string
}

export interface ITemplateDetails{
    table: ITable,
    unit: string,
    id?: string
}

export interface IGuide{
    table_name: string,
    published: boolean,
    id?: string,
    unit: string,
}


export interface ITableDetails extends IGuide{
    filters: IFilters,
    table: ITable
}

export interface ITable{
    bottom: boolean,
    left: boolean,
    right: boolean,
    top: boolean,
    description: string,
    image: string,
    subtitle: string,
    title: string,
    body: [string][][]
}

export interface IFilters{
    tag: string,
    vendor: string,
    product_type: string,
    select_all: boolean,
    products: string[]
}

