<template>
    <div></div>
</template>

<script>
    import {mapState} from 'vuex'
    import 'v-toaster/dist/v-toaster.css'
    import {requestStatus} from "@/services/services";

    export default {
        name: "tooltip-component",
        computed: {
            ...mapState({
              shop_status: state => state.shop.status,
              guide_status: state => state.guides.status,
            }),
        },
      methods: {
        checkStatus: function(status) {
          if (status[status.change_field] === requestStatus.error) {
            this.$toaster.error(status.messages || "Bad request");
          }
        }
      },
      watch: {
        shop_status(newValue) {
          this.checkStatus(newValue);
        },
        guide_status(newValue) {
          this.checkStatus(newValue);
        },
      }
    }
</script>

<style scoped>

</style>