import * as TYPES from "./types";
import * as API from "./api";
import {requestStatus} from "@/services/services";
import {
    ILoadParameters,
    IDeleteParameters,
    ILoadDetailsParameters,
    ICreateParameters, IEditParameters, LIST_NAMES
} from "@/store/modules/guides/interfaces";


export default {
    [TYPES.LOAD]({commit}: any, {listName, filter}: ILoadParameters) {
        let status = requestStatus.loading;
        let field = `load_${listName}`;
        commit(TYPES.SET_STATUS, {status, field});

        API.load(listName, filter)
            .then(({response, status: loaderStatus}: any) => {
                status = loaderStatus;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE, {...response.data, listName});
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },

    [TYPES.LOAD_DETAILS]({commit}: any, {listName, id}: ILoadDetailsParameters) {
        let status = requestStatus.loading;
        let field = `load_details_${listName}`;
        commit(TYPES.SET_STATUS, {status, field});

        API.loadDetails(listName, id)
            .then(({response, status: loaderStatus}: any) => {
                status = loaderStatus;
                if (status === requestStatus.success) {
                    commit(TYPES.RECEIVE_DETAILS, {data: response.data, listName});
                }
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },

    [TYPES.LOAD_GUIDE_DETAILS]({commit}: any, {id}: ILoadDetailsParameters) {
        let status = requestStatus.loading;
        let field = `load_details_${LIST_NAMES.Guides}`;
        commit(TYPES.SET_STATUS, {status, field});
        Promise.all([API.loadDetails(LIST_NAMES.Guides, id), API.load(LIST_NAMES.Products, `attached=all&limit=25&offset=0`)]
            ).then(([guideResponse, productsResponse]) => {
            if (guideResponse.status === requestStatus.success) {
                commit(TYPES.RECEIVE_DETAILS, {data: guideResponse.response.data, listName: LIST_NAMES.Guides});
            }
            if (productsResponse.status === requestStatus.success) {
                commit(TYPES.RECEIVE, {...productsResponse.response.data, listName: LIST_NAMES.Products});
            }

            commit(TYPES.SET_STATUS, {
                status: guideResponse.status,
                field
            });
        });
    },

    [TYPES.CREATE]({commit}: any, {listName, data}: ICreateParameters) {
        let status = requestStatus.loading;
        let field = `create_${listName}`;
        commit(TYPES.SET_STATUS, {status, field});

        API.create(listName, data)
            .then(({status: loaderStatus}: any) => {
                status = loaderStatus;
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },

    [TYPES.EDIT]({commit}: any, {listName, id, data}: IEditParameters) {
        let status = requestStatus.loading;
        let field = `edit_${listName}`;
        commit(TYPES.SET_STATUS, {status, field});

        API.edit(listName, id, data)
            .then(({status: loaderStatus}: any) => {
                status = loaderStatus;
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },

    [TYPES.DELETE]({commit}: any, {listName, id}: IDeleteParameters) {
        let status = requestStatus.loading;
        let field = `delete_${listName}`;
        commit(TYPES.SET_STATUS, {status, field});

        API.onDelete(listName, id)
            .then(({status: loaderStatus}: any) => {
                status = loaderStatus;
            })
            .finally(() => {
                commit(TYPES.SET_STATUS, {
                    status: status === requestStatus.loading ? requestStatus.error : status,
                    field
                });
            });
    },

}