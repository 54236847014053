import Http from "@/services/http";
import { service_url } from "@/services/config";
import {LIST_NAMES} from "@/store/modules/guides/interfaces";

export function load (listName: LIST_NAMES, filter: string) {
    return Http._axios({
        url: `${service_url}/api/v1/${listName}/?${filter}`,
        method: 'get'
    })
}

export function create (listName: LIST_NAMES, data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/${listName}/create/`,
        method: 'post',
        data
    })
}

export function edit (listName: LIST_NAMES, id: string, data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/${listName}/${id}/edit/`,
        method: 'patch',
        data
    })
}

export function onDelete (listName: LIST_NAMES, id: string) {
    return Http._axios({
        url: `${service_url}/api/v1/${listName}/${id}/delete/`,
        method: 'delete'
    })
}

export function loadDetails (listName: LIST_NAMES, id: string) {
    return Http._axios({
        url: `${service_url}/api/v1/${listName}/${id}/`,
        method: 'get'
    })
}

export function loadGuides () {
    return Http._axios({
        url: `${service_url}/api/v1/guides/`,
        method: 'get'
    })
}

export function createGuide (data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/guides/`,
        method: 'post',
        data
    })
}

export function editGuide (id: string, data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/guides/${id}/`,
        method: 'post',
        data
    })
}

export function loadTemplate (search: string) {
    return Http._axios({
        url: `${service_url}/api/v1/templates/?search=${search}`,
        method: 'get'
    })
}

export function createTemplate (data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/templates/create/`,
        method: 'post',
        data
    })
}
