import actions from "./actions";
import mutations from "./mutations";
import {IState, LIST_NAMES} from "@/store/modules/guides/interfaces";
export * from "./types";

const state: IState = {
    status: { change_field: null },
    guide: {},
    table: {},
    filters: {},
    unit: "",
    isEdit: false,
    products: []
};

for (const Key in LIST_NAMES) {
    // if(LIST_NAMES.hasOwnProperty(Key)){
        // @ts-ignore
        state[`list_${LIST_NAMES[Key]}`] = [];
        // @ts-ignore
        state[`${LIST_NAMES[Key]}_count`] = 0;
        // @ts-ignore
        state[`${LIST_NAMES[Key]}_details`] = null;
    // }
}

export default {
    state,
    actions,
    mutations,
};
