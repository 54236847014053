// action types
export const LOAD = "LOAD";
export const CREATE = "CREATE";
export const EDIT = "EDIT";
export const DELETE = "DELETE";
export const LOAD_DETAILS = "LOAD_DETAILS";
export const LOAD_GUIDE_DETAILS = "LOAD_GUIDE_DETAILS";

// mutation types
export const RECEIVE = "RECEIVE";
export const RECEIVE_DETAILS = "RECEIVE_DETAILS";
export const SET_GUIDE = "SET_GUIDE";
export const SET_TEMPLATE = "SET_TEMPLATE";
export const UPDATE_GUIDE = "UPDATE_GUIDE";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_TABLES = "UPDATE_TABLES";
export const SET_STATUS = "SET_STATUS";
export const CLEAR_DATA = "CLEAR_DATA";