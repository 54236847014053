import Http from "@/services/http";
import { service_url } from "@/services/config";

export function loadShops () {
    return Http._axios({
        url: `${service_url}/api/v1/shop/`,
        method: 'get'
    })
}
export function loadSettings () {
    return Http._axios({
        url: `${service_url}/api/v1/shop/settings/`,
        method: 'get'
    })
}

export function editSettings (data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/shop/settings/`,
        method: 'patch',
        data: data
    })
}

export function changePlan (data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/shop/plan/`,
        method: 'post',
        data: data
    })
}

export function acceptPlan (data: any) {
    return Http._axios({
        url: `${service_url}/api/v1/shop/plan/accept/`,
        method: 'post',
        data: data
    })
}
export function loadProducts () {
    return Http._axios({
        url: `${service_url}/api/v1/products/`,
        method: 'get'
    })
}