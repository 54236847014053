import * as TYPES from "./types";
import {
    IFilters, IGuide,
    IReceiveDetailsPayload,
    IReceivePayload,
    IState, ITable, ITableDetails,
} from "@/store/modules/guides/interfaces";

export default {
    [TYPES.RECEIVE](state: IState, {listName, results, count}: IReceivePayload) {
        state[`list_${listName}`] = [...results];
        state[`${listName}_count`] = count;
    },

    [TYPES.RECEIVE_DETAILS](state: IState, {listName, data}: IReceiveDetailsPayload) {
        state[`${listName}_details`] = {...data};
    },

    [TYPES.SET_GUIDE](state: IState, payload: ITableDetails) {
        state.guide = {
            table_name: payload.table_name,
            published: payload.published,
            id: payload.id,
            unit: payload.unit,
        };
        state.filters = {...state.filters, ...payload.filters};
        state.table = {...state.table, ...payload.table};
        state.isEdit = false;
    },

    [TYPES.UPDATE_GUIDE](state: IState, payload: IGuide) {
        state.guide = {...state.guide, ...payload};
        state.isEdit = true;
    },

    [TYPES.SET_TEMPLATE](state: IState, payload: IGuide) {
        state.templates_details = {...payload};
    },

    [TYPES.UPDATE_FILTERS](state: IState, payload: IFilters) {
        state.filters = {...state.filters, ...payload};
        state.isEdit = true;
    },

    [TYPES.UPDATE_TABLES](state: IState, payload: ITable) {
        state.table = {...state.table, ...payload};
        state.isEdit = true;
    },

    [TYPES.SET_STATUS](state: IState, payload: any) {
        state.status = {
            ...state.status,
            [payload.field]: payload.status,
            messages: payload.messages,
            change_field: payload.field,
        };
    },

    [TYPES.CLEAR_DATA](state: IState) {
        state.guide = {};
        state.unit = "";
        state.filters = {};
        state.table = {};
        state.products = [];
    }
};
